import React from 'react';
import { Menu } from 'antd';
import { ScheduleOutlined, SolutionOutlined, InfoCircleOutlined ,CalendarOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom'
export const MainMenu = () => {
  const location = useLocation()
  return  <Menu selectedKeys={location.pathname}  mode="inline">
    <Menu.Item key="/calendar" icon={<CalendarOutlined style={{fontSize: 20}} />}><Link to="calendar">ניהול יומן</Link></Menu.Item>
    <Menu.Item key="/customers" icon={<SolutionOutlined style={{fontSize: 20}}/>}><Link to="customers">ניהול לקוחות</Link></Menu.Item>
    <Menu.Item key="/updates" icon={<InfoCircleOutlined style={{fontSize: 20}}/>}><Link to="updates">ניהול עדכונים</Link></Menu.Item>
    <Menu.Item key="/classes" icon={<ScheduleOutlined style={{fontSize: 20}}/>}><Link to="classes">חוגים</Link></Menu.Item>
  </Menu>
}