import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, DatePicker, TimePicker, Select } from 'antd'
import { useForm } from 'antd/lib/form/util'
import moment from 'moment'
import {
  LoadingOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { GET } from '../../server'

const {TextArea} = Input

export const AddAppointmentDialog = ({onSave, ...rest})=>{
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [timeSlots, setTimeslots] = useState([])
  const [duration, setDuration] = useState(120)
  const [clients, setClients] = useState([])

  useEffect(()=>{
    (async ()=>{
      try {
        let clients = await GET("clients")
        clients.sort((a, b)=>{
          const sa = `${a.fname.trim()} ${a.lname.trim()}`
          const sb = `${b.fname.trim()} ${b.lname.trim()}`
          if (sa < sb) return -1
          if (sa > sb) return 1
          return 0
        })
        setClients(clients)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  const onOK = async ()=>{
    try {
      const values = await form.validateFields()
      const day = values.date.startOf("day")
      const t = values.time
      onSave({
        clientId: values.clientId,
        start: day.add(t.hours(), "hours").add(t.minutes(), "minutes").toISOString(true),
        end: day.add(duration, "minutes").toISOString(true),
        comment: values.comment
      })
    } catch (error) {
      console.log(error)
    }
  }

  const disabledDate = current=>{
    return current < moment().startOf("day") || current > moment().add(14, "days")
  }

  const disabledHours = ()=>{
    let hours = []
    for(var i = 0; i < 24; i++) hours.push(i)
    const avalable = timeSlots.map(slot=>{
      return moment(slot).hour()
    })
    return hours.filter(x=>!avalable.includes(x))
  }

  const disabledMinutes = selectedHour=>{
    const minutes = ["00", "30"]
    
    const avalable = timeSlots.map(slot=>{
      return moment(slot).format("HH:mm")
    })
    const hour = moment().hour(selectedHour).format("HH")
    return minutes.filter(x=>!avalable.includes(`${hour}:${x}`))
  }

  const onValuesChange = async changedValues=>{
    if (changedValues.hasOwnProperty("date")) {
      if (changedValues.date) {
        try {
          setLoading(true)
          const timeslots = await GET("timeslots", {date: changedValues.date.toISOString(true)})
          setTimeslots(timeslots.slots)
          setDuration(timeslots.duration)
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      } else {
        setTimeslots([])
        setDuration(120)
        form.setFieldsValue({
          time: null
        })
      }
    }
  }


  return <Modal title="הוספת הזמנת מקום" {...rest} onOk={onOK}>
      <Form form={form} labelCol={{span: 5}} onValuesChange={onValuesChange}>
        <Form.Item name="clientId" label="שם הלקוח" required rules={[
          {required: true, message:"יש למלא שם לקוח"}
        ]}>
          <Select showSearch placeholder="בחר לקוח" options={clients.map(client=>{
            return {
              value: client.id,
              label: `${client.fname} ${client.lname} [${client.phone}]`
            }
          })} filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }/>
        </Form.Item>
        <Form.Item label="תאריך" name="date" required rules={[
          {required: true, message:"יש למלא תאריך"}
        ]}>
          <DatePicker disabledDate={disabledDate}/>
        </Form.Item>
        <Form.Item label="שעה" name="time" required rules={[
          {required: true, message:"יש למלא שעה"}
        ]} dependencies={["date"]}>
          <TimePicker hideDisabledOptions disabled={loading} suffixIcon={loading?<LoadingOutlined />:<ClockCircleOutlined />} inputReadOnly minuteStep={30} showSecond={false} disabledHours={disabledHours} disabledMinutes={disabledMinutes}/>
        </Form.Item>
        <Form.Item label="הערות" name="comment">
          <TextArea/>
        </Form.Item>
      </Form>
  </Modal>
}