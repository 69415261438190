export const classtypes = [
  {
    id: 0,
    name: "ללא מנוי"
  },
  {
    id: 1,
    name: "מנוי חודשי"
  },
  {
    id: 2,
    name: "מנוי שנתי"
  },
  {
    id: 3,
    name: "חוג פעמיים בשבוע"
  },
  {
    id: 4,
    name: "חוג פעם בשבוע"
  },
  {
    id: 5,
    name: "חוג פעמיים בשבוע + נבחרת"
  }
];