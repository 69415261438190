import React from 'react';
import './App.css';
import './main.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { MainMenu } from './components/menu'
import { Updates } from './pages/updates';
import { Calendar } from './pages/calendar';
import Customers from './pages/customers';
import { ConfigProvider, Layout } from 'antd';
import { Classes } from './pages/classes';
import moment from 'moment';
import heIL from 'antd/es/locale/he_IL';
import 'moment/locale/he';

const {Sider, Content} = Layout
function App() {
  moment.locale("he")
  return (
    <div className="App">
      <ConfigProvider locale={heIL} direction="rtl">
      <Router>
        <Route path="/" exact><Redirect to="calendar" /></Route>
        <Layout style={{overflow:"hidden"}}>
          <Sider theme="light" breakpoint="lg">
            <MainMenu/>
          </Sider>
          <Content className="main">
          <Switch>
            <Route path="/calendar">
              <Calendar />
            </Route>
            <Route path="/customers">
              <Customers />
            </Route>
            <Route path="/updates">
              <Updates />
            </Route>
            <Route path="/classes">
              <Classes />
            </Route>
          </Switch>
          </Content>
        </Layout>
      </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
