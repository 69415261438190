import React, { useEffect, useState } from 'react'
import { PageHeader, Button, Modal, Input, Form, Checkbox, Card, Row, Col, DatePicker } from 'antd'
import { GET, POST, DELETE, PUT } from '../../server'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
const { TextArea } = Input;


export const Updates = () => {
  const [updates, setUpdates] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {
        const updates = await GET("updates")
        setUpdates(updates)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const saveUpdate = async () => {
    try {
      setConfirmLoading(true)
      let values = await form.validateFields()
      if (values.id) {
        const result = await PUT("updates", values)
        if (result.affectedRows) {
          Object.assign(updates.find(x=>x.id === values.id), values)
          setUpdates([...updates])
        }
      } else {
        const result = await POST("updates", values)
        values.id = result.insertId
        setUpdates([...updates, values])
      }
      setShowDialog(false)
      form.resetFields()
    } catch (error) {
      console.error(error)
    } finally {
      setConfirmLoading(false)
    }
  }

  const deleteUpdate = async (update) => {
    try {
      await DELETE("updates", update)
      setUpdates(updates.filter(x=>x.id !== update.id))
    } catch (error) {
      console.error(error)
    }
  }

  const editUpdate = async (update) => {
    form.setFieldsValue({...update, date: moment(update.date)})
    setShowDialog(true)
  }

  const createNew = ()=>{
    form.setFieldsValue({
      date: moment()
    })
    setShowDialog(true)
  }


  return <div className="page">
    <PageHeader
      className="site-page-header"
      title="עדכונים"
      subTitle={`סה"כ ${updates.length} עדכונים קיימים`}
      extra={[]}
      footer={
        <div style={{ padding: "1em" }}>
          <Button onClick={() => createNew()} icon={<PlusOutlined />} type="primary" >הוספת עדכון</Button>
        </div>
      }
    />
    <div className="content">
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
        {
          updates.map(update => {
            return <Col xs={24} sm={24} md={12} lg={8} xl={6}  key={update.id}>
              <Card title={<div>
                <Text code>{moment(update.date).format("DD/MM/YYYY HH:mm")}</Text>
                <Title level={4}>{update.title}</Title>
                <Text strong>{update.subtitle}</Text>
              </div>} 
              actions={[
                <DeleteOutlined key="delete"  onClick={()=>deleteUpdate(update)}/>,
                <EditOutlined key="edit"   onClick={()=>editUpdate(update)}/>,
              ]}>
                <p style={{whiteSpace: "pre-wrap"}}>{update.content}</p>
              </Card>
            </Col>
          })
        }
      </Row>
    </div>
    <Modal
      title="עריכת עדכון"
      visible={showDialog}
      onOk={saveUpdate}
      onCancel={() => {
        form.resetFields()
        setShowDialog(false)
      }}
      confirmLoading={confirmLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="id" style={{display: "none"}}>
        <Input type="hidden"/>
        </Form.Item>
        
        <Form.Item label="כותרת" name="title" required
          rules={[{ required: true, message: 'נא להזין כותרת!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="תת כותרת" name="subtitle" >
          <Input />
        </Form.Item>
        <Form.Item label="תוכן" name="content" required
          rules={[{ required: true, message: 'נא להזין תוכן!' }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="תאריך פרסום" name="date">
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="splash" valuePropName="checked">
          <Checkbox>הודעה קופצת</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  </div>
}
