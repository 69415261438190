import qs from 'qs'

export const endpoint = "https://climbr.co.il/app/"

const options = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json;charset=UTF-8"
  }
}


export const GET = async (resource, data) => {
  let url = `${endpoint}${resource}`
  if (data) {
    url += qs.stringify(data, { addQueryPrefix: true });
  }
  let config = {
    method: "GET",
    ...options
  };
  const response = await fetch(url, config);
  return handleResponse(response);
}

export const POST = async (resource, data) => {
  let url = `${endpoint}${resource}`
  let config = {
    method: "POST",
    body: JSON.stringify(data),
    ...options
  };
  const response = await fetch(url, config);
  return handleResponse(response);
}

export const PUT = async (resource, data) => {
  let url = `${endpoint}${resource}`
  let config = {
    method: "PUT",
    body: JSON.stringify(data),
    ...options
  };
  const response = await fetch(url, config);
  return handleResponse(response);
}



export const DELETE = async (resource, data) => {
  let url = `${endpoint}${resource}`
  let config = {
    method: "DELETE",
    body: JSON.stringify(data),
    ...options
  };
  const response = await fetch(url, config);
  return handleResponse(response);
}


const handleResponse = async res => {
  let json = await res.json();
  if (res.status === 200) {
     return json
  } else {
    console.log(res)
    throw json
  }
}