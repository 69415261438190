import React, { useCallback, useRef, useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { GET, POST } from '../../server';
import { PageHeader, Dropdown, Menu, Button, Row, Col, Grid } from 'antd';
import Icon, { DownOutlined, LeftOutlined, RightOutlined, ReloadOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { AddAppointmentDialog } from './addNewDialog';
const AgendaSvg = () => <svg viewBox="0 0 24 24" fill="black" width="14px" height="14px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zM5 7V5h14v2H5zm2 4h10v2H7zm0 4h7v2H7z" /></svg>
const DaySvg = () => <svg viewBox="0 0 24 24" fill="black" width="14px" height="14px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z" /></svg>
const WeekSvg = () => <svg viewBox="0 0 24 24" fill="black" width="14px" height="14px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z" /></svg>
const AgendaIcon = props => <Icon component={AgendaSvg} {...props} />
const DayIcon = props => <Icon component={DaySvg} {...props} />
const WeekIcon = props => <Icon component={WeekSvg} {...props} />
const { useBreakpoint } = Grid;

export const Calendar = () => {
  const calendarRef = useRef()
  const [title, setTitle] = useState("")
  const [showAddNew, setShowAddNew] = useState(false)
  const screens = useBreakpoint();
  
  useEffect(() => {
    const calendar = calendarRef.current
    if (calendar) {

    }
  }, [calendarRef])

  const getEvents = async (fetchInfo, successCallback, failureCallback) => {
    try {
      const bookings = await GET("bookings", {
        start: fetchInfo.startStr,
        end: fetchInfo.endStr,
        withClient: true
      })
      const events = bookings.map(booking => {
        return {
          title: `${booking.fname} ${booking.lname}`,
          start: booking.start,
          end: booking.end,
          comment: booking.comment
        }
      })
      successCallback(events)
    } catch (error) {
      console.log(error)
      failureCallback(error)
    }
  }

  const eventRender = useCallback(info => {
    const { el, event } = info
    if (info.event.extendedProps.comment && el.classList.contains("fc-list-item")) {
      const title = el.getElementsByClassName("fc-list-item-title")[0]
      const description = document.createElement("span")
      description.className = "fc-list-item-description"
      description.innerText = event.extendedProps.comment
      title.append(description)
    }
  }, [])

  const datesRender = useCallback(info => {
    setTitle(info.view.title)
  }, [])
  const setView = e => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.changeView(e.key)
    }
  }
  const moveNext = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.next()
    }
  }
  const movePrev = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.prev()
    }
  }
  const moveToday = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.today()
    }
  }

  const refresh = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.refetchEvents()
    }
  }

  const viewmenu = (
    <Menu onClick={setView} >
      <Menu.Item key="listWeek" icon={<AgendaIcon />}>
        רשימה
      </Menu.Item>
      <Menu.Item key="timeGridDay" icon={<DayIcon />}>
        יום
      </Menu.Item>
      <Menu.Item key="timeGridWeek" icon={<WeekIcon />}>
        שבוע
      </Menu.Item>
    </Menu>
  );

  const addNew = () => {
    setShowAddNew(true)
  }

  const addAppointment = async appointment=>{
    try {
      const added = await POST("bookings", appointment)
      if (added.insertId){
        refresh()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return <div className="page">
    <PageHeader
      className="site-page-header"
      title="יומן"
      subTitle={title}
      extra={[
        <Dropdown overlay={viewmenu} key="view">
          <Button icon={<DownOutlined />}>{screens.md?"בחירת מבט":"מבט"}</Button>
        </Dropdown>,
        <Button shape={!screens.md && "circle"} onClick={movePrev} key="prev" icon={<RightOutlined />} >
        {screens.md && "קודם"} </Button>,
        <Button shape={!screens.md && "circle"} onClick={moveToday} key="today" icon={<CalendarOutlined />} type="primary">{screens.md && "היום"}</Button>,
        <Button shape={!screens.md && "circle"} onClick={moveNext} key="next" icon={<LeftOutlined />} >{screens.md && "הבא"}</Button>
      ]}
      footer={<Row gutter="10" style={{paddingBottom: 10}}>
        <Col><Button onClick={refresh} icon={<ReloadOutlined />}>{screens.md && "רענן"}</Button></Col>
        <Col><Button onClick={addNew} icon={<PlusOutlined />}>{screens.md && "הוספה ידנית"}</Button></Col>
      </Row>}
    />
    <div style={{flex: 1, overflow:"hidden", minHeight: 0}}>
      <FullCalendar
        ref={calendarRef}
        defaultView="timeGridDay"
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
        header={false}
        dir="rtl"
        locale="he"
        events={getEvents}
        slotEventOverlap={false}
        eventRender={eventRender}
        datesRender={datesRender}
        height="parent"
      />
    </div>
    <AddAppointmentDialog visible={showAddNew} onCancel={() => setShowAddNew(false)} onSave={addAppointment} />
  </div>
}