import React, { useEffect, useState } from 'react'
import { PageHeader, Button, Table, Input, Checkbox } from 'antd'
import { GET } from '../../server'
import moment from 'moment'
import { classtypes } from '../../data';
const { Search } = Input;

const Customers = () => {
  const [customers, setCustomers] = useState([])
  const [search, setSearch] = useState()
  const [showLate, setShowLate] = useState(false)
  useEffect(() => {
    (async () => {
      try {
        const customers = await GET("clients")
        setCustomers(customers)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const renderDate = (text, record, index) => {
    return text && moment(text).format("DD/MM/YYYY")
  }

  var coptions = classtypes.map(x=>{
    return {
      value: x.id,
      text: x.name
    }
  });


  const columns = [
    {
      title: 'שם פרטי',
      dataIndex: 'fname',
      key: 'fname',
      sorter: (a, b) => a.fname.localeCompare(b.fname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'שם משפחה',
      dataIndex: 'lname',
      key: 'lname',
      sorter: (a, b) => a.lname.localeCompare(b.lname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'דוא"ל',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'טלפון',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'סוג מנוי',
      dataIndex: 'membership',
      key: 'membership',
      render: (text, record, index) => {
        const membership = record.membership || 0
        return coptions.find(x => x.value === membership).text
      }, 
      filters: coptions,
      onFilter: (value, record) => record.membership === value,
      sorter: (a, b) => a.membership - b.membership,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'תאריך התחלה',
      dataIndex: 'startdate',
      key: 'startdate',
      render: renderDate
    },
    {
      title: 'תאריך סיום',
      dataIndex: 'enddate',
      key: 'enddate',
      render: renderDate
    },
  ];

  const rowClassName = (record, index) => {
    return (record.enddate && moment(record.enddate).isBefore(moment.now())) ? "late" : ""
  }
  let data = customers
  if (search) {
    data = customers.filter(x=>{
      return x.fname.includes(search) ||
      x.lname.includes(search) ||
      x.email.includes(search) ||
      x.phone.includes(search)
    })
  }
  if (showLate) {
    data = data.filter(x=>{
      return x.enddate &&  moment(x.enddate).isBefore(moment.now())
    })
  }

  
  return <div className="page">
    <PageHeader
      className="site-page-header"
      title="ניהול לקוחות"
      subTitle=""
      extra={[<Search allowClear key="search" placeholder="חיפוש" style={{ width: 400 }} size="large" onChange={e=>setSearch(e.target.value)}></Search>]}
      footer={
        <div>
          <Checkbox checked={showLate} onChange={e=>setShowLate(e.target.checked)} >הצג רק חורגים</Checkbox>
        </div>
      }
    />
    <div className="content">
      <Table className="customers" allowClear dataSource={data} columns={columns} pagination={{ defaultPageSize: 20 }} rowClassName={rowClassName} rowKey="id" />
    </div>
  </div>
}

export default Customers