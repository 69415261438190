import { Button, Checkbox, DatePicker, Form, Input, Modal, PageHeader, Popconfirm, Select, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'
import { DELETE, GET, POST, PUT } from '../../server';
import { classtypes } from '../../data';

export const Classes = () => {
  const [date, setDate] = useState(moment())
  const [classes, setClasses] = useState([])
  const [currentClass, setCurrentClass] = useState()
  const [loadingClasses, setLoadingClasses] = useState(false)
  const [students, setStudents] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [clients, setClients] = useState([])
  const [attendance, setAttendance] = useState([])

  useEffect(() => {
    (async () => {
      try {
        setLoadingClasses(true)
        const [classes, clients, attendance] = await Promise.all([
          GET("classes"), GET("clients"), GET("attendance/" + date.format("YYYY-MM-DD"))
        ])
        setClasses(classes)
        const now = moment()
        const sel = classes.find(x => x.day === date.weekday() && moment(x.start, "HH:mm:ss").isBefore(now) && moment(x.end, "HH:mm:ss").isAfter(now))
        if (sel) {
          setCurrentClass(sel.id)
        } else {
          setCurrentClass(undefined)
        }
        clients.sort((a, b) => {
          const sa = `${a.fname.trim()} ${a.lname.trim()}`
          const sb = `${b.fname.trim()} ${b.lname.trim()}`
          if (sa < sb) return -1
          if (sa > sb) return 1
          return 0
        })
        setClients(clients)
        setAttendance(attendance)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingClasses(false)
      }
    })()
  }, [date])

  useEffect(() => {
    if (currentClass) {
      (async () => {
        try {
          const students = await GET("clients/class/" + currentClass, { date: date.format("YYYY-MM-DD")})
          setStudents(students)
        } catch (error) {
          console.log(error)
        } finally {
        }
      })()
    } else {
      setStudents([])
    }
  }, [currentClass])

  var coptions = classtypes.map(x => {
    return {
      value: x.id,
      text: x.name
    }
  });
  const renderDate = (text, record, index) => {
    return text && moment(text).format("DD/MM/YYYY")
  }

  const rowClassName = (record, index) => {
    return (record.enddate && moment(record.enddate).isBefore(moment.now())) ? "late" : ""
  }

  const columns = [
    {
      title: 'שם',
      key: 'name',
      render: (text, record, index) => {
        return record.fname + " " + record.lname
      },
      sorter: (a, b) => a.fname.localeCompare(b.fname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'טלפון',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'סוג מנוי',
      dataIndex: 'membership',
      key: 'membership',
      render: (text, record, index) => {
        const membership = record.membership || 0
        return coptions.find(x => x.value === membership).text
      },
      sorter: (a, b) => a.membership - b.membership,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'תאריך סיום',
      dataIndex: 'enddate',
      key: 'enddate',
      render: renderDate
    },
    {
      title: 'נוחכות',
      align: "center",
      render: (text, record, index) => {
        return <Checkbox checked={
          attendance.find(x=>x.clientid === record.id)
        } onChange={async e=>{
          try {
            const data = {
              clientid: record.id, 
              date: date.format("YYYY-MM-DD")
            }
            if (e.target.checked) {
              const result = await POST("attendance",data)
              setAttendance([...attendance, data])
            } else {
              const result = await DELETE("attendance", data)
              setAttendance(attendance.filter(x=>x.clientid !== record.id))
            }
          } catch (error) {
            console.log(error)
          }
        }}></Checkbox>
      }
    },
    {
      title: 'פעולות',
      align: "center",
      render: (text, record, index) => {
        return <Popconfirm 
        title={`האם אתה בטוח שברצונך להסיר ${record.fname + " " + record.lname} מהחוג?`}
        
        onConfirm={async ()=>{
          try {
            const result = await DELETE("classes/student", {
              clientid: record.id,
              classid: curClass.id
            })
            setStudents(students.filter(x=>x.id !== record.id))
          } catch (error) {
            console.log(error)
          }
        }}
        >
          <Button icon={<DeleteOutlined/>} danger type="link"></Button>
        </Popconfirm>
      }
    }
  ]

  const addStudent = async () => {
    setConfirmLoading(true)
    let values = await form.validateFields()
    const result = await POST("classes/student", values)
    setStudents([...students, clients.find(x=>x.id === values.clientid)])
    setConfirmLoading(false)
    setShowDialog(false)
    form.resetFields()
  }

  const curClass = classes?.find(x => x.id === currentClass)

  return <div className="page">
    <PageHeader
      className="site-page-header"
      title="חוגים"
      // subTitle={`סה"כ ${updates.length} עדכונים קיימים`}
      extra={[
        <DatePicker key="date" value={date} onChange={setDate} allowClear={false} />,
        <Select style={{ width: 200 }} loading={loadingClasses} options={
          classes
            .filter(x => x.day === date?.weekday())
            .map(x => {
              return {
                key: x.id,
                label: `${date.format("dddd")} ${moment(x.start, "HH:mm:ss").format("HH:mm")} - ${moment(x.end, "HH:mm:ss").format("HH:mm")}`,
                value: x.id
              }
            })
        } value={currentClass} onChange={setCurrentClass} placeholder="בחירת חוג" />
      ]}
      footer={
        <div style={{ padding: "1em" }}>
          <Button disabled={!currentClass} onClick={() => setShowDialog(true)} icon={<PlusOutlined />} type="primary" >הוספת משתתף</Button>
        </div>
      }
    />
    <div className="content">
      <Table className="customers" columns={columns} dataSource={students} rowClassName={rowClassName} rowKey="id"></Table>
    </div>
    <Modal
      title="הוספת משתתף"
      visible={showDialog}
      onOk={addStudent}
      onCancel={() => {
        form.resetFields()
        setShowDialog(false)
      }}
      confirmLoading={confirmLoading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="classid" initialValue={curClass?.id} style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="clientid" label="שם הלקוח" required rules={[
          { required: true, message: "יש למלא שם לקוח" }
        ]}>
          <Select showSearch placeholder="בחר לקוח" options={clients
            .filter(client => {
              return curClass && client.membership !== 0
            })
            .map(client => {
              return {
                value: client.id,
                label: `${client.fname} ${client.lname} [${client.phone}]`
              }
            })} filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            } />
        </Form.Item>
        <Form.Item label="חוג" >
          {curClass && `${date.format("dddd")} ${moment(curClass.start, "HH:mm:ss").format("HH:mm")} - ${moment(curClass.end, "HH:mm:ss").format("HH:mm")}`}
        </Form.Item>
      </Form>
    </Modal>
  </div>
}